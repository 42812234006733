import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import styles from '../styles/Header.module.css';

const BurgerButton = ({ open, setOpen }) => (
  <button aria-controls="nav-menu" aria-expanded="false" onClick={() => setOpen(!open)} className={styles['burger-menu']} type="button">
    {/* SR only stuff , okay ? <span >Menu</span> */}
    <FontAwesomeIcon icon={open ? solid('xmark') : solid('bars')} className={open ? `${styles['nav-link']} menu-icon}` : 'menu-icon'} size="2x" />
  </button>
);

export default BurgerButton;

BurgerButton.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
