/* eslint-disable */
import styles from "../styles/SkillsSection.module.css";
import SkillsIllustration from "./SkillsIllustration";

const SkillsSection = () => {
  const frontEnd = [
    "JavaScript",
    "React",
    "Redux",
    "NextJS",
    "HTML5",
    "CSS3",
    "Sass",
    "Bootstrap",
    "Tailwind CSS",
  ];
  const backEnd = ["Ruby", "Ruby on Rails", "Postgresql", "SQL", "Firebase"];
  const tools = [
    "Git",
    "GitHub",
    "Mobile/Responsive Development",
    "TDD",
    "Chrome Dev Tools",
    "VSCode",
    "Figma",
  ];

  return (
    <section className={styles["skills-section"]} id="skills">
      <h2>Skills</h2>

      <div className={styles["godfather"]}>
        <SkillsIllustration />
        <div className={styles["skills-container"]}>
          <div className={styles["skills-set"]}>
            <h3 className={styles["skills-set-title"]}>Front-end</h3>
            <p className={styles["skills"]}>
              {frontEnd.map((item) => (
                <span key={item}>
                  {item} {item != frontEnd[frontEnd.length - 1] ? " | " : ""}{" "}
                </span>
              ))}
            </p>
          </div>

          <div className={styles["skills-set"]}>
            <h3 className={styles["skills-set-title"]}>Back-End</h3>
            <p className={styles["skills"]}>
              {backEnd.map((item) => (
                <span key={item}>
                  {item} {item != backEnd[backEnd.length - 1] ? " | " : ""}{" "}
                </span>
              ))}
            </p>
          </div>

          <div className={styles["skills-set"]}>
            <h3 className={styles["skills-set-title"]}>Tools & Platforms</h3>
            <p className={styles["skills"]}>
              {tools.map((item) => (
                <span key={item}>
                  {item} {item != tools[tools.length - 1] ? " | " : ""}{" "}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
