/* eslint-disable */
import PropTypes from 'prop-types';
import styles from '../styles/Header.module.css';

const Menu = ({ open, setOpen }) => (
  <ul className={`${open ? styles['menu-slide'] : ''} ${styles['nav-menu']}`}>
    <li className={styles['nav-link']}><a className={styles['animation']} href="#skills" onClick={() => setOpen(!open)}>SKILLS</a></li>
    <li className={styles['nav-link']}><a className={styles['animation']} href="#projects" onClick={() => setOpen(!open)}>PROJECTS</a></li>
    <li className={styles['nav-link']}><a className={styles['animation']} href="#contact" onClick={() => setOpen(!open)}>CONTACT</a></li>
    {/* <li className={styles['nav-link']}><a href="www.google.com">BLOG</a></li> */}
    <li className={styles['nav-link']}><a className={styles['animation']} href="https://drive.google.com/file/d/1PWaUOKtHkFMLkTGhfa6O97w0DVEcEkFh/view?usp=sharing" onClick={() => setOpen(!open)} target="_blank" rel="noreferrer">RESUME</a></li>
    {/* <li className={styles['nav-link']}><FontAwesomeIcon icon={regular('moon')} /></li> */}
  </ul>
);

export default Menu;

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
};
