/* eslint-disable */
import React, { useState, useEffect } from "react";
import styles from "../styles/Header.module.css";
import BurgerButton from "./BurgerButton";
import Menu from "./Menu";

const Header = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <header>
      <div className={styles["header-container"]}>
        <span className={styles.logo}>
          &#60;Hayet
          <span className={styles["colored-text"]}>Fe </span>
          /&#62;
        </span>
        <BurgerButton open={open} setOpen={setOpen} />
        <nav>
          <Menu open={open} setOpen={setOpen} />
        </nav>
      </div>
    </header>
  );
};

export default Header;
