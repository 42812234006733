import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareArrowUpRight,
} from '@fortawesome/free-solid-svg-icons';
import { faSquareGithub } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import styles from '../styles/ProjectIllustration.module.css';
import styles2 from '../styles/ProjectCard.module.css';

const Project = ({ project }) => (
  <article className={styles2['project-card']}>
    <div className={styles['project-illustration']}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="845"
        height="504"
        viewBox="0 0 845 504"
        fill="none"
      >
        <defs>
          <pattern
            id={project.idNames[0]}
            width="1"
            height="1"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <image
              xlinkHref={project.desktopImg}
              width="100"
              height="100"
              preserveAspectRatio="none"
            />
          </pattern>

          <pattern
            id={project.idNames[1]}
            width="1"
            height="1"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <image
              xlinkHref={project.mobileImg}
              width="100"
              height="100"
              preserveAspectRatio="none"
            />
          </pattern>
        </defs>

        <g id="devices/bro">
          <g id="Shadow--inject-1">
            <path
              id="main-color"
              d="M397.13 503.831C611.542 503.912 785.361 493.835 785.365 481.322C785.37 468.81 611.559 458.601 397.147 458.52C182.736 458.439 8.91665 468.517 8.91193 481.029C8.90721 493.542 182.718 503.751 397.13 503.831Z"
              fill="#6246ea"
            />
            <path
              id="Vector"
              opacity="0.6"
              d="M397.13 503.831C611.542 503.912 785.361 493.835 785.365 481.322C785.37 468.81 611.559 458.601 397.147 458.52C182.736 458.439 8.91665 468.517 8.91193 481.029C8.90721 493.542 182.718 503.751 397.13 503.831Z"
              fill="white"
            />
          </g>
          <g id="Laptop--inject-1">
            <path
              id="main-color_2"
              d="M80.2459 1.02978L726.737 1.2735C732.492 1.27567 738.01 3.521 742.078 7.51556C746.146 11.5101 748.43 16.9267 748.428 22.5736L748.27 440.678L58.4027 440.418L58.5603 22.3136C58.5624 16.6701 60.8477 11.2584 64.9139 7.26748C68.9802 3.27657 74.4948 1.03301 80.2459 1.02978Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.920603"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_2"
              d="M720.439 35.611L86.5393 35.3721L86.4068 386.893L720.306 387.132L720.439 35.611Z"
              fill="white"
              className={styles[project.classNames[0]]}
            />
            <path
              id="Vector_3"
              d="M720.439 35.611L86.5393 35.3721L86.4068 386.893L720.306 387.132L720.439 35.611Z"
              stroke="#263238"
              strokeWidth="0.690452"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_3"
              d="M641.964 419.489L167.857 419.31L167.848 442.23L641.956 442.409L641.964 419.489Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_4"
              d="M641.96 430.542L167.853 430.363L167.848 442.251L641.956 442.429L641.96 430.542Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_5"
              d="M2.59485 455.766C2.59485 455.766 10.3911 465.112 33.7047 472.754L776.055 473.034C776.055 473.034 793.357 468.786 801.161 456.901L2.59485 455.766Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.920603"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_6"
              d="M797.247 439.333L6.62527 439.035C3.53197 439.034 1.02343 441.493 1.02228 444.529L1.01969 451.389C1.01855 454.424 3.52524 456.886 6.61854 456.887L797.24 457.185C800.334 457.186 802.842 454.726 802.843 451.691L802.846 444.831C802.847 441.796 800.34 439.334 797.247 439.333Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.920603"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_4"
              d="M403.35 20.3877C406.695 20.389 409.408 17.7288 409.409 14.4461C409.41 11.1635 406.699 8.50129 403.354 8.50003C400.009 8.49876 397.296 11.1589 397.295 14.4416C397.293 17.7243 400.004 20.3864 403.35 20.3877Z"
              fill="#263238"
            />
            <path
              id="main-color_7"
              d="M323.323 8.46984L171.891 8.41276C171.493 8.41261 171.099 8.33557 170.732 8.18608C170.364 8.03658 170.031 7.81756 169.75 7.54149C169.468 7.26541 169.245 6.93769 169.093 6.57707C168.941 6.21644 168.863 5.82996 168.863 5.43968V5.43968C168.863 5.0494 168.942 4.66298 169.094 4.30246C169.247 3.94195 169.47 3.6144 169.751 3.33854C170.032 3.06268 170.366 2.8439 170.734 2.69469C171.101 2.54548 171.495 2.46877 171.893 2.46892L323.325 2.526C324.127 2.53164 324.894 2.84674 325.461 3.40314C326.027 3.95955 326.348 4.71249 326.353 5.49905V5.49905C326.353 6.28725 326.033 7.04306 325.465 7.60019C324.897 8.15732 324.126 8.47015 323.323 8.46984V8.46984Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_8"
              d="M632.183 8.58624L480.751 8.52915C479.948 8.52885 479.177 8.21544 478.61 7.65788C478.042 7.10032 477.723 6.34427 477.723 5.55607V5.55607C477.729 4.76952 478.05 4.01682 478.617 3.46084C479.184 2.90486 479.952 2.59034 480.753 2.58531L632.185 2.6424C632.989 2.6427 633.759 2.95608 634.327 3.51364C634.894 4.07119 635.213 4.82724 635.213 5.61544V5.61544C635.213 6.40365 634.893 7.15945 634.325 7.71658C633.757 8.27371 632.986 8.58654 632.183 8.58624V8.58624Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_9"
              d="M742.457 124.94C741.998 124.94 741.558 124.762 741.232 124.446C740.906 124.129 740.72 123.7 740.715 123.25L740.733 74.0094C740.739 73.5576 740.924 73.1258 741.25 72.8064C741.576 72.487 742.016 72.3053 742.477 72.3002C742.935 72.3057 743.373 72.4884 743.695 72.8086C744.017 73.1288 744.198 73.5608 744.198 74.0107L744.179 123.251C744.179 123.699 743.997 124.129 743.674 124.445C743.351 124.762 742.913 124.94 742.457 124.94V124.94Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_10"
              d="M65.0147 124.685C64.7885 124.685 64.5647 124.641 64.3558 124.556C64.1469 124.471 63.9572 124.346 63.7974 124.189C63.6375 124.032 63.5108 123.846 63.4244 123.641C63.3379 123.436 63.2935 123.216 63.2935 122.994L63.3121 73.7542C63.3122 73.3042 63.4931 72.8724 63.8156 72.5525C64.138 72.2325 64.576 72.0501 65.0345 72.045V72.045C65.495 72.0504 65.935 72.2324 66.2605 72.5521C66.586 72.8717 66.7712 73.3036 66.7764 73.7555L66.7578 122.996C66.7522 123.446 66.566 123.875 66.2397 124.191C65.9134 124.508 65.4732 124.685 65.0147 124.685V124.685Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.691688"
              strokeMiterlimit="10"
            />
          </g>
          <g id="Mobile--inject-1">
            <path
              id="Vector_5"
              d="M828.259 208.356L700.89 208.308C692.103 208.305 684.976 215.292 684.973 223.915L684.874 487.765C684.871 496.387 691.991 503.38 700.779 503.383L828.148 503.431C836.935 503.435 844.061 496.447 844.064 487.825L844.164 223.975C844.167 215.352 837.046 208.359 828.259 208.356Z"
              fill="#263238"
            />
            <path
              id="Vector_6"
              d="M837.084 240.766L692.041 240.711L691.954 470.994L836.997 471.049L837.084 240.766Z"
              fill="white"
              className={styles[project.classNames[1]]}
            />
            <path
              id="Vector_7"
              d="M837.084 240.766L692.041 240.711L691.954 470.994L836.997 471.049L837.084 240.766Z"
              stroke="#263238"
              strokeWidth="0.52825"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_11"
              d="M780.334 224.623L748.803 224.611C748.253 224.611 747.725 224.396 747.336 224.014C746.947 223.632 746.729 223.114 746.729 222.574C746.729 222.035 746.948 221.517 747.337 221.135C747.726 220.754 748.254 220.539 748.804 220.54L780.335 220.551C780.886 220.552 781.413 220.766 781.802 221.148C782.191 221.53 782.409 222.048 782.409 222.588C782.409 223.128 782.19 223.645 781.801 224.027C781.412 224.409 780.884 224.623 780.334 224.623Z"
              fill="#6246ea"
            />
            <path
              id="Vector_8"
              opacity="0.4"
              d="M780.334 224.623L748.803 224.611C748.253 224.611 747.725 224.396 747.336 224.014C746.947 223.632 746.729 223.114 746.729 222.574C746.729 222.035 746.948 221.517 747.337 221.135C747.726 220.754 748.254 220.539 748.804 220.54L780.335 220.551C780.886 220.552 781.413 220.766 781.802 221.148C782.191 221.53 782.409 222.048 782.409 222.588C782.409 223.128 782.19 223.645 781.801 224.027C781.412 224.409 780.884 224.623 780.334 224.623Z"
              fill="white"
            />
            <path
              id="main-color_12"
              d="M773.079 484.012C773.08 482.341 772.576 480.708 771.63 479.318C770.685 477.928 769.341 476.845 767.768 476.205C766.195 475.565 764.464 475.397 762.794 475.723C761.124 476.048 759.59 476.852 758.385 478.033C757.181 479.214 756.36 480.719 756.028 482.357C755.695 483.996 755.865 485.694 756.516 487.238C757.167 488.782 758.27 490.102 759.685 491.031C761.1 491.959 762.765 492.455 764.467 492.456C766.75 492.457 768.94 491.568 770.556 489.984C772.171 488.401 773.078 486.252 773.079 484.012Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.704333"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_13"
              d="M768.828 479.735L760.116 479.732L760.113 488.282L768.825 488.285L768.828 479.735Z"
              fill="#6246ea"
              stroke="#263238"
              strokeWidth="0.52825"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_9"
              opacity="0.6"
              d="M768.828 479.735L760.116 479.732L760.113 488.282L768.825 488.285L768.828 479.735Z"
              fill="white"
              stroke="#263238"
              strokeWidth="0.52825"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_10"
              d="M768.828 479.735L760.116 479.732L760.113 488.282L768.825 488.285L768.828 479.735Z"
              stroke="#263238"
              strokeWidth="0.52825"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
    <div className={styles2['project-info']}>
      <h3 className={styles2['project-title']}>{project.name}</h3>
      <ul className={styles2['technologies-list']}>
        {project.technologies.map((tech, i) => (
          <li className={styles2.technology} key={`${tech + i}`}>
            {tech}
          </li>
        ))}
      </ul>
      <p className={styles2['project-description']}>{project.description}</p>
      <div className={styles2['project-links']}>
        {project.liveDemoLink ? (
          <a
            className={styles2.social}
            href={project.liveDemoLink}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSquareArrowUpRight} size="2xl" />
          </a>
        ) : (
          ' '
        )}
        {project.githubLink ? (
          <a
            className={styles2.social}
            href={project.githubLink}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSquareGithub} size="2xl" />
          </a>
        ) : (
          ' '
        )}
      </div>
    </div>
  </article>
);

Project.propTypes = {
  project: PropTypes.shape({
    idNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    desktopImg: PropTypes.string.isRequired,
    mobileImg: PropTypes.string.isRequired,
    classNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    liveDemoLink: PropTypes.string.isRequired,
    githubLink: PropTypes.string.isRequired,
    technologies: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default Project;
