/* eslint-disable */

import ContactMeIllustration from "./ContactMeIllustration";
import styles from "../styles/ContactMeSection.module.css";

const ContactMeSection = () => {
  return (
    <section className={styles["contact-me-section"]} id="contact">
      <h2 /*data-aos="fade-down"*/>Contact Me</h2>
      <p className={styles["contact-me-text"]}>
        If you think i’m a perfect fit for the job, or you’re just up for a
        chat, feel free to send me a message using this form!
      </p>
      <div className={styles["form-container"]}>
        <ContactMeIllustration />
        <form action="https://formspree.io/f/xeqdoera" method="POST">
          <input
            /* data-aos="fade-right"*/ aria-label="Full Name"
            type="text"
            name="fullname"
            id="fullname"
            placeholder="Full name*"
            maxLength="30"
            required=""
          />

          <input
            /*data-aos="fade-right"*/ aria-label="Email"
            type="email"
            name="email"
            id="email"
            placeholder="Email address*"
            required=""
          />

          <textarea
            /*data-aos="fade-right"*/ aria-label="Message"
            name="message"
            id="massage"
            maxLength="500"
            placeholder="Enter text here*"
            required=""
            spellCheck="false"
          ></textarea>
          <div /*data-aos="fade-right"*/>
            <button className={styles["btn"]} type="submit">
              Get in touch
            </button>
            <span className="error"></span>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactMeSection;
