/* eslint-disable */
import styles from "../styles/ContactMeIllustration.module.css"; 

const ContactMeIllustration = () => {
  return (
    <div className={styles['contact-me-illustration']} /*data-aos="zoom-in"*/>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="555"
        height="497"
        viewBox="0 0 555 497"
        fill="none"
      >
        <g id="mailbox/bro">
          <g id="background-simple--inject-1">
            <path
              id="main-color"
              d="M458.827 112.486C435.949 99.9586 412.115 94.9301 386.106 98.1501C354.068 102.105 328.764 106.766 298.108 94.8272C272.333 84.7997 248.793 69.8319 224.21 57.4225C173.925 31.9126 124.008 24.0171 86.3094 74.3311C66.2544 101.105 51.022 131.85 40.5093 164.285C23.5125 216.613 9.54456 274.558 17.2049 329.209C26.8942 398.49 86.3094 431.014 157.384 405.915C186.129 395.77 213.874 380.92 244.53 376.671C308.679 367.761 361.552 426.661 438.478 412.341C571.071 387.698 561.278 168.578 458.827 112.486Z"
              fill="#6246ea"
            />
            <path
              id="Vector"
              opacity="0.7"
              d="M458.827 112.486C435.949 99.9586 412.115 94.9301 386.106 98.1501C354.068 102.105 328.764 106.766 298.108 94.8272C272.333 84.7997 248.793 69.8319 224.21 57.4225C173.925 31.9126 124.008 24.0171 86.3094 74.3311C66.2544 101.105 51.022 131.85 40.5093 164.285C23.5125 216.613 9.54456 274.558 17.2049 329.209C26.8942 398.49 86.3094 431.014 157.384 405.915C186.129 395.77 213.874 380.92 244.53 376.671C308.679 367.761 361.552 426.661 438.478 412.341C571.071 387.698 561.278 168.578 458.827 112.486Z"
              fill="white"
            />
          </g>
          <g id="Clouds--inject-1">
            <path
              id="main-color_2"
              d="M478.897 56.0404C469.561 50.9385 465.326 55.1877 461.93 50.9385C458.533 46.6893 471.266 35.6619 445.756 24.6346C445.756 24.6346 444.065 0.87441 409.263 0.0216298C374.461 -0.83115 383.842 23.7818 374.505 29.7219C365.169 35.6619 362.625 26.3843 353.288 32.2655C343.952 38.1468 348.201 50.0857 340.57 51.7765C332.939 53.4674 322.735 44.1456 313.399 50.0857C304.062 56.0257 304.062 61.113 299.828 61.113C295.593 61.113 287.095 57.7166 282.008 61.113C276.921 64.5094 270.966 68.7586 270.966 68.7586H485.631C485.631 68.7586 488.234 61.1277 478.897 56.0404Z"
              fill="#6246ea"
            />
            <path
              id="Vector_2"
              opacity="0.5"
              d="M478.897 56.0404C469.561 50.9385 465.326 55.1877 461.93 50.9385C458.533 46.6893 471.266 35.6619 445.756 24.6346C445.756 24.6346 444.065 0.87441 409.263 0.0216298C374.461 -0.83115 383.842 23.7818 374.505 29.7219C365.169 35.6619 362.625 26.3843 353.288 32.2655C343.952 38.1468 348.201 50.0857 340.57 51.7765C332.939 53.4674 322.735 44.1456 313.399 50.0857C304.062 56.0257 304.062 61.113 299.828 61.113C295.593 61.113 287.095 57.7166 282.008 61.113C276.921 64.5094 270.966 68.7586 270.966 68.7586H485.631C485.631 68.7586 488.234 61.1277 478.897 56.0404Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M235.458 246.784C228.9 243.211 225.916 246.181 223.534 243.211C221.152 240.241 230.091 232.478 212.212 224.729C212.212 224.729 211.021 208.041 186.57 207.438C162.119 206.835 168.691 224.126 162.134 228.302C155.576 232.478 153.797 225.92 147.239 230.096C140.682 234.271 143.652 242.608 138.3 243.799C132.948 244.99 125.773 238.432 119.186 242.608C112.599 246.784 112.628 250.357 109.658 250.357C106.688 250.357 100.704 247.975 97.1311 250.357C93.5582 252.738 89.3826 255.723 89.3826 255.723H240.222C240.222 255.723 242.015 250.357 235.458 246.784Z"
              fill="white"
            />
            <path
              id="Vector_4"
              opacity="0.5"
              d="M235.458 246.784C228.9 243.211 225.916 246.181 223.534 243.211C221.152 240.241 230.091 232.478 212.212 224.729C212.212 224.729 211.021 208.041 186.57 207.438C162.119 206.835 168.691 224.126 162.134 228.302C155.576 232.478 153.797 225.92 147.239 230.096C140.682 234.271 143.652 242.608 138.3 243.799C132.948 244.99 125.773 238.432 119.186 242.608C112.599 246.784 112.628 250.357 109.658 250.357C106.688 250.357 100.704 247.975 97.1311 250.357C93.5582 252.738 89.3826 255.723 89.3826 255.723H240.222C240.222 255.723 242.015 250.357 235.458 246.784Z"
              fill="white"
            />
          </g>
          <g id="Plants--inject-1">
            <path
              id="main-color_3"
              d="M421.878 495.222H514.802C513.023 483.857 505.715 485.856 504.745 468.242C504.26 459.42 512.479 448.393 511.758 436.748C511.17 427.147 501.716 417.31 500.981 406.327C500.319 396.417 508.333 385.419 507.612 374.745C506.862 364.659 497.32 354.969 496.423 344.471C495.6 334.179 503.51 323.137 502.569 312.786C501.628 302.435 491.88 292.922 490.806 282.718C489.733 272.235 497.393 260.943 496.217 250.915C494.982 240.256 484.925 231.052 483.558 221.23C482.088 210.305 489.16 198.631 487.498 189.192C485.455 177.635 474.692 169.269 472.428 160.741C468.208 144.847 468.737 129.541 463.753 128.541C458.445 127.483 457.225 142.495 453.196 159.271C451.167 167.696 440.654 176.238 438.919 187.81C437.522 197.176 444.962 208.57 443.742 219.451C442.654 229.199 432.847 238.565 431.891 249.166C430.994 259.134 438.949 270.118 438.169 280.542C437.405 290.673 427.877 300.333 427.245 310.625C426.613 320.917 434.788 331.68 434.229 341.839C433.655 352.249 424.319 362.086 423.834 372.098C423.334 382.699 431.7 393.388 431.288 403.21C430.832 414.105 421.628 424.074 421.29 433.601C420.879 445.158 429.362 455.818 429.098 464.625C428.539 483.739 419.79 479.754 419.482 495.134L421.878 495.222Z"
              fill="#6246ea"
            />
            <path
              id="Vector_5"
              d="M461.768 159.08V490.929"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_6"
              d="M476.28 193.867L461.15 206.938L448.77 190.427"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_7"
              d="M476.28 251.636L461.15 264.692L448.77 248.195"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_8"
              d="M476.28 305.949L461.15 319.02L448.77 302.509"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_9"
              d="M476.28 370.599L461.15 383.655L448.77 367.158"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_10"
              d="M476.28 435.925L461.15 448.981L448.77 432.484"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="main-color_4"
              d="M358.581 495.222H430.318C428.936 486.4 423.304 487.988 422.555 474.403C422.172 467.551 428.509 459.082 427.965 450.084C427.51 442.732 420.217 435.072 419.643 426.559C419.129 418.913 425.363 410.385 424.76 402.181C424.187 394.388 416.791 386.919 416.129 378.818C415.468 370.716 421.599 362.336 420.878 354.352C420.158 346.368 412.615 339.016 411.821 331.15C410.998 323.049 416.909 314.345 415.997 306.581C415.041 298.362 407.278 291.261 406.234 283.689C405.058 275.249 410.557 266.236 409.263 258.958C407.69 250.033 399.383 243.564 397.648 236.992C394.384 224.729 394.795 212.893 390.943 212.129C386.841 211.305 385.9 222.891 382.797 235.859C381.239 242.358 373.108 248.96 371.77 257.914C370.697 265.148 376.431 273.94 375.49 282.336C374.652 289.864 367.095 297.039 366.345 305.273C365.654 312.963 371.8 321.446 371.197 329.489C370.609 337.311 363.272 344.765 362.757 352.705C362.243 360.645 368.638 368.967 368.153 376.803C367.668 384.64 360.508 392.433 360.14 400.167C359.743 408.342 366.198 416.59 365.889 424.177C365.536 432.587 358.434 440.35 358.17 447.702C357.846 456.612 364.404 464.86 364.198 471.638C363.757 486.341 357.008 483.327 356.773 495.163L358.581 495.222Z"
              fill="#6246ea"
            />
            <path
              id="Vector_11"
              d="M389.384 235.756V491.914"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_12"
              d="M400.574 262.604L388.899 272.691L379.342 259.943"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_13"
              d="M400.574 307.184L388.899 317.27L379.342 304.538"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_14"
              d="M400.574 349.117L388.899 359.204L379.342 346.471"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_15"
              d="M400.574 399.02L388.899 409.106L379.342 396.359"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_16"
              d="M400.574 449.451L388.899 459.523L379.342 446.79"
              stroke="black"
              strokeMiterlimit="10"
            />
          </g>
          <g id="Mailbox--inject-1">
            <g id="Vector_17">
              <path
                d="M294.638 494.531V197.264H310.326V494.531"
                fill="#2E353A"
              />
              <path
                d="M294.638 494.531V197.264H310.326V494.531"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="main-color_5">
              <path
                d="M286.801 494.531V197.264H302.489V494.531"
                fill="#6246ea"
              />
              <path
                d="M286.801 494.531V197.264H302.489V494.531"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_18" style={{mixBlendMode: "multiply", opacity: "0.3"}} >
              <path
                d="M302.489 197.264H286.801V232.551H302.489V197.264Z"
                fill="#2E353A"
              />
            </g>
            <path
              id="main-color_6"
              d="M353.744 81.2122H237.501L288.168 205.659H404.426V131.879C404.426 125.224 403.115 118.634 400.567 112.486C398.02 106.338 394.287 100.752 389.58 96.047C384.874 91.3419 379.287 87.6101 373.138 85.0647C366.989 82.5194 360.399 81.2102 353.744 81.2122Z"
              fill="#6246ea"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="Vector_19" style={{mixBlendMode: "multiply", opacity: "0.3"}}>
              <path
                d="M279.302 183.869L288.124 205.659H404.426V131.879C404.426 130.762 404.426 129.644 404.308 128.541H279.332L279.302 183.869Z"
                fill="black"
              />
            </g>
            <path
              id="Vector_20"
              d="M352.891 128.541H340.658"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_21"
              d="M404.308 128.541H367.286"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_22"
              d="M237.502 81.2122C250.943 81.2122 263.834 86.5518 273.339 96.0565C282.843 105.561 288.183 118.452 288.183 131.894V205.674H186.82V131.879C186.824 118.44 192.165 105.553 201.669 96.0513C211.174 86.5498 224.062 81.2122 237.502 81.2122Z"
              fill="#2E353A"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="main-color_7"
              d="M288.168 197.264H200.832V205.659H288.168V197.264Z"
              fill="#6246ea"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="main-color_8"
              d="M288.168 197.264H107.423V205.659H288.168V197.264Z"
              fill="#6246ea"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_23"
              opacity="0.3"
              d="M288.168 197.264H200.832V205.659H288.168V197.264Z"
              fill="black"
            />
            <path
              id="Vector_24"
              d="M361.302 51.2178H325.029V76.6983H361.302V51.2178Z"
              fill="#2E353A"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_25"
              d="M327.97 51.2178H320.133V130.615H327.97V51.2178Z"
              fill="white"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_26"
              d="M324.044 141.392C328.916 141.392 332.866 137.442 332.866 132.57C332.866 127.698 328.916 123.748 324.044 123.748C319.172 123.748 315.222 127.698 315.222 132.57C315.222 137.442 319.172 141.392 324.044 141.392Z"
              fill="#2E353A"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="paper-planes--inject-1">
            <path
              id="Vector_27"
              d="M194.671 101.591C215.138 111.986 250.72 128.644 283.537 137.025"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="2 4"
            />
            <path
              id="Vector_28"
              d="M177.498 92.5482C177.498 92.5482 184.056 96.2534 194.671 101.649"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="2 4"
            />
            <path
              id="Vector_29"
              d="M170.132 92.0043L184.85 57.0403L78.12 31.0011L170.132 92.0043Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="main-color_9"
              d="M177.307 61.2748L170.132 92.0043L78.12 31.0011L177.307 61.2748Z"
              fill="#6246ea"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_30"
              d="M177.307 61.2748L160.56 75.625L78.12 31.0011L177.307 61.2748Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_31"
              d="M184.85 57.0403L199.759 50.9826L78.12 31.0011L184.85 57.0403Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_32"
              d="M149.401 86.5641L152.856 88.6813"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_33"
              d="M130.551 75.0074L142.123 82.0943"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_34"
              d="M117.554 158.933V121.013L226.033 138.451L117.554 158.933Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="main-color_10"
              d="M122.861 127.836L117.554 158.933L226.033 138.451L122.861 127.836Z"
              fill="#6246ea"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_35"
              d="M122.861 127.836L132.727 147.552L226.033 138.451L122.861 127.836Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_36"
              d="M117.554 121.014L106.173 109.633L226.033 138.451L117.554 121.014Z"
              fill="white"
              stroke="#2E353A"
              strokeWidth="1.2629"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_37"
              d="M156.399 158.227L165.809 156.316"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_38"
              d="M123.347 164.946L147.004 160.138"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_39"
              d="M104.483 156.595C104.483 156.595 19.2046 171.298 3.03115 247.754C-13.1423 324.21 72.1357 328.621 86.8388 291.863C101.542 255.106 36.8483 240.403 22.1452 275.69C7.44208 310.978 51.5514 371.26 111.834 374.201C161.928 376.642 302.372 318.167 389.723 303.391C389.723 303.391 454.416 285.482 554.397 289.408"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="2 4"
            />
          </g>
          <g id="Fence--inject-1">
            <path
              id="Vector_40"
              d="M525.477 396.255H549.987"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_41"
              d="M525.477 432.513H549.987"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="Vector_42">
              <path d="M9.39758 431.543H522.536V397.226H9.39758" fill="white" />
              <path
                d="M9.39758 431.543H522.536V397.226H9.39758"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_43">
              <path
                d="M45.0674 494.766V322.123C45.0677 321.699 45.1538 321.28 45.3206 320.891C45.4874 320.502 45.7314 320.15 46.0378 319.858L59.447 307.228C60.0509 306.664 60.8535 306.361 61.6797 306.386C62.506 306.41 63.289 306.761 63.858 307.361L75.6204 319.873C76.1541 320.455 76.4527 321.215 76.4585 322.005V494.766"
                fill="#2E353A"
              />
              <path
                d="M45.0674 494.766V322.123C45.0677 321.699 45.1538 321.28 45.3206 320.891C45.4874 320.502 45.7314 320.15 46.0378 319.858L59.447 307.228C60.0509 306.664 60.8535 306.361 61.6797 306.386C62.506 306.41 63.289 306.761 63.858 307.361L75.6204 319.873C76.1541 320.455 76.4527 321.215 76.4585 322.005V494.766"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_44">
              <path
                d="M41.2593 496.237V322.122C41.2589 321.698 41.3461 321.277 41.5156 320.888C41.6851 320.498 41.9332 320.148 42.2444 319.858L55.6536 307.228C55.9517 306.947 56.3024 306.727 56.6855 306.581C57.0687 306.436 57.4768 306.367 57.8865 306.379C58.2962 306.392 58.6995 306.485 59.0732 306.653C59.447 306.821 59.7838 307.062 60.0645 307.361L71.827 319.873C72.3669 320.451 72.6665 321.214 72.6651 322.005V496.237"
                fill="white"
              />
              <path
                d="M41.2593 496.237V322.122C41.2589 321.698 41.3461 321.277 41.5156 320.888C41.6851 320.498 41.9332 320.148 42.2444 319.858L55.6536 307.228C55.9517 306.947 56.3024 306.727 56.6855 306.581C57.0687 306.436 57.4768 306.367 57.8865 306.379C58.2962 306.392 58.6995 306.485 59.0732 306.653C59.447 306.821 59.7838 307.062 60.0645 307.361L71.827 319.873C72.3669 320.451 72.6665 321.214 72.6651 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_45">
              <path
                d="M158.134 494.766V322.122C158.135 321.699 158.221 321.28 158.387 320.891C158.554 320.502 158.798 320.15 159.105 319.858L172.529 307.228C172.827 306.947 173.177 306.727 173.561 306.581C173.944 306.436 174.352 306.367 174.762 306.379C175.171 306.392 175.575 306.485 175.948 306.653C176.322 306.821 176.659 307.062 176.94 307.361L188.702 319.873C189.242 320.451 189.541 321.214 189.54 322.005V494.766"
                fill="#2E353A"
              />
              <path
                d="M158.134 494.766V322.122C158.135 321.699 158.221 321.28 158.387 320.891C158.554 320.502 158.798 320.15 159.105 319.858L172.529 307.228C172.827 306.947 173.177 306.727 173.561 306.581C173.944 306.436 174.352 306.367 174.762 306.379C175.171 306.392 175.575 306.485 175.948 306.653C176.322 306.821 176.659 307.062 176.94 307.361L188.702 319.873C189.242 320.451 189.541 321.214 189.54 322.005V494.766"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_46">
              <path
                d="M154.958 496.237V322.123C154.958 321.698 155.045 321.277 155.215 320.888C155.384 320.498 155.632 320.148 155.943 319.858L169.353 307.228C169.957 306.664 170.759 306.361 171.585 306.386C172.412 306.41 173.195 306.761 173.764 307.361L185.526 319.873C186.07 320.45 186.375 321.212 186.379 322.005V496.237"
                fill="white"
              />
              <path
                d="M154.958 496.237V322.123C154.958 321.698 155.045 321.277 155.215 320.888C155.384 320.498 155.632 320.148 155.943 319.858L169.353 307.228C169.957 306.664 170.759 306.361 171.585 306.386C172.412 306.41 173.195 306.761 173.764 307.361L185.526 319.873C186.07 320.45 186.375 321.212 186.379 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_47">
              <path
                d="M271.833 494.766V322.123C271.833 321.698 271.92 321.277 272.09 320.888C272.259 320.498 272.507 320.148 272.818 319.858L286.228 307.228C286.832 306.664 287.634 306.361 288.46 306.386C289.287 306.41 290.07 306.761 290.639 307.361L302.401 319.873C302.945 320.45 303.25 321.212 303.254 322.005V496.237"
                fill="#2E353A"
              />
              <path
                d="M271.833 494.766V322.123C271.833 321.698 271.92 321.277 272.09 320.888C272.259 320.498 272.507 320.148 272.818 319.858L286.228 307.228C286.832 306.664 287.634 306.361 288.46 306.386C289.287 306.41 290.07 306.761 290.639 307.361L302.401 319.873C302.945 320.45 303.25 321.212 303.254 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_48">
              <path
                d="M268.672 496.237V322.122C268.672 321.699 268.759 321.28 268.925 320.891C269.092 320.502 269.336 320.15 269.643 319.858L283.066 307.228C283.365 306.947 283.715 306.727 284.098 306.581C284.482 306.436 284.89 306.367 285.299 306.379C285.709 306.392 286.112 306.485 286.486 306.653C286.86 306.821 287.197 307.062 287.477 307.361L299.24 319.873C299.78 320.451 300.079 321.214 300.078 322.005V496.237"
                fill="white"
              />
              <path
                d="M268.672 496.237V322.122C268.672 321.699 268.759 321.28 268.925 320.891C269.092 320.502 269.336 320.15 269.643 319.858L283.066 307.228C283.365 306.947 283.715 306.727 284.098 306.581C284.482 306.436 284.89 306.367 285.299 306.379C285.709 306.392 286.112 306.485 286.486 306.653C286.86 306.821 287.197 307.062 287.477 307.361L299.24 319.873C299.78 320.451 300.079 321.214 300.078 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_49">
              <path
                d="M385.547 494.766V322.123C385.547 321.699 385.634 321.28 385.8 320.891C385.967 320.502 386.211 320.15 386.518 319.858L399.927 307.228C400.531 306.664 401.333 306.361 402.159 306.386C402.986 306.41 403.769 306.761 404.338 307.361L416.1 319.873C416.634 320.455 416.932 321.215 416.938 322.005V494.766"
                fill="#2E353A"
              />
              <path
                d="M385.547 494.766V322.123C385.547 321.699 385.634 321.28 385.8 320.891C385.967 320.502 386.211 320.15 386.518 319.858L399.927 307.228C400.531 306.664 401.333 306.361 402.159 306.386C402.986 306.41 403.769 306.761 404.338 307.361L416.1 319.873C416.634 320.455 416.932 321.215 416.938 322.005V494.766"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_50">
              <path
                d="M382.371 496.237V322.122C382.371 321.698 382.458 321.277 382.628 320.888C382.797 320.498 383.045 320.148 383.356 319.858L396.766 307.228C397.064 306.947 397.414 306.727 397.798 306.581C398.181 306.436 398.589 306.367 398.999 306.379C399.408 306.392 399.812 306.485 400.185 306.653C400.559 306.821 400.896 307.062 401.177 307.361L412.939 319.873C413.479 320.451 413.779 321.214 413.777 322.005V496.237"
                fill="white"
              />
              <path
                d="M382.371 496.237V322.122C382.371 321.698 382.458 321.277 382.628 320.888C382.797 320.498 383.045 320.148 383.356 319.858L396.766 307.228C397.064 306.947 397.414 306.727 397.798 306.581C398.181 306.436 398.589 306.367 398.999 306.379C399.408 306.392 399.812 306.485 400.185 306.653C400.559 306.821 400.896 307.062 401.177 307.361L412.939 319.873C413.479 320.451 413.779 321.214 413.777 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_51">
              <path
                d="M499.246 494.766V322.122C499.246 321.699 499.333 321.28 499.499 320.891C499.666 320.502 499.91 320.15 500.217 319.858L513.64 307.228C513.939 306.947 514.289 306.727 514.672 306.581C515.056 306.436 515.464 306.367 515.873 306.379C516.283 306.392 516.686 306.485 517.06 306.653C517.434 306.821 517.771 307.062 518.051 307.361L529.814 319.873C530.354 320.451 530.653 321.214 530.652 322.005V494.766"
                fill="#2E353A"
              />
              <path
                d="M499.246 494.766V322.122C499.246 321.699 499.333 321.28 499.499 320.891C499.666 320.502 499.91 320.15 500.217 319.858L513.64 307.228C513.939 306.947 514.289 306.727 514.672 306.581C515.056 306.436 515.464 306.367 515.873 306.379C516.283 306.392 516.686 306.485 517.06 306.653C517.434 306.821 517.771 307.062 518.051 307.361L529.814 319.873C530.354 320.451 530.653 321.214 530.652 322.005V494.766"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g id="Vector_52">
              <path
                d="M496.071 496.237V322.123C496.07 321.698 496.157 321.277 496.327 320.888C496.496 320.498 496.745 320.148 497.056 319.858L510.465 307.228C511.069 306.664 511.871 306.361 512.698 306.386C513.524 306.41 514.307 306.761 514.876 307.361L526.638 319.873C527.182 320.45 527.487 321.212 527.491 322.005V496.237"
                fill="white"
              />
              <path
                d="M496.071 496.237V322.123C496.07 321.698 496.157 321.277 496.327 320.888C496.496 320.498 496.745 320.148 497.056 319.858L510.465 307.228C511.069 306.664 511.871 306.361 512.698 306.386C513.524 306.41 514.307 306.761 514.876 307.361L526.638 319.873C527.182 320.45 527.487 321.212 527.491 322.005V496.237"
                stroke="#2E353A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <path
              id="Vector_53"
              d="M60.0498 307.36V319.946"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_54"
              d="M65.093 314.859V344.648"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_55"
              d="M161.148 316.3V353.984"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_56"
              d="M166.015 311.433V327.842"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_57"
              d="M283.067 307.228V344.265"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_58"
              d="M290.05 310.213V364.938"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_59"
              d="M387.929 316.3V357.63"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_60"
              d="M403.735 311.433V321.77"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_61"
              d="M506.48 310.816V332.709"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_62"
              d="M519.86 313.256V376.48"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_63"
              d="M495.541 405.666H482.161"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_64"
              d="M495.541 416.002H448.726"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_65"
              d="M321.045 416.002H299.769"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_66"
              d="M209.183 416.002H187.908"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_67"
              d="M40.7742 410.532H19.4988"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_68"
              d="M40.774 420.251H32.2609"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_69"
              d="M310.106 410.532H299.769"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_70"
              d="M197.024 422.074H186.688"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_71"
              d="M153.856 422.074H143.519"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_72"
              d="M536.872 416.002H527.756"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_73"
              d="M549.031 416.002H543.561"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_74"
              d="M72.1358 352.793C72.1358 352.793 68.4601 384.39 64.0491 416.722C59.6382 449.054 67.7249 482.136 69.9304 485.077"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_75"
              d="M57.5945 416.752C58.3002 410.444 58.8884 406.459 58.8884 406.459"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_76"
              d="M68.4453 481.401C68.4453 481.401 55.2125 478.46 55.2125 454.936C55.2125 445.276 55.8301 435.38 56.5652 427"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_77"
              d="M179.468 315.3C179.468 315.3 182.409 317.506 177.263 342.501C172.117 367.496 172.852 404.254 179.468 424.838"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_78"
              d="M186.82 361.615C186.82 361.615 179.468 383.67 184.614 414.546"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_79"
              d="M270.628 375.568C270.628 375.568 274.303 396.888 277.979 418.928C281.655 440.968 278.714 471.859 275.774 479.931C272.833 488.003 269.892 494.634 269.892 494.634"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_80"
              d="M284.478 483.827C281.199 493.884 277.126 482.136 277.126 482.136"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_81"
              d="M284.346 408.665C284.346 408.665 291.271 444.408 287.36 471.315"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_82"
              d="M269.157 420.427C269.157 420.427 276.509 435.866 269.893 450.569"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_83"
              d="M406.337 422.604C407.321 427.098 408.613 431.519 410.204 435.836"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_84"
              d="M412.41 346.177C407.744 360.985 404.785 376.277 403.588 391.756C403.149 399.588 403.588 407.444 404.896 415.178"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_85"
              d="M398.442 413.782C398.442 413.782 405.058 438.777 408.734 447.599C412.41 456.421 410.94 477.726 406.529 488.018"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_86"
              d="M497.658 357.204C497.658 357.204 512.361 383.67 498.393 427.029"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_87"
              d="M511.17 402.401C511.259 410.665 510.523 421.133 507.215 429.955C500.599 447.599 497.658 453.48 497.658 453.48"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_88"
              d="M510.156 388.081C510.156 388.081 510.641 391.242 510.935 396.108"
              stroke="#2E353A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ContactMeIllustration;