/* eslint-disable */

import Carousel from "react-material-ui-carousel";
import styles from "../styles/ProjectsSection.module.css";
import Project from "./Project";
import nightOwlDesktop from "../images/night_owl_desktop.png";
import nightOwlMobile from "../images/night_owl_mobile.png";
import quickmathsDesktop from "../images/quickmaths_desktop.png";
import quickmathsMobile from "../images/quickmaths_mobile.png";
import countriesDesktop from "../images/countries_desktop.png";
import countriesMobile from "../images/countries_mobile.png";
import globalTravelDesktop from "../images/globaltravel_desktop.png"
import globalTravelMobile from "../images/globaltravel_mobile.png"
import pythonCodeDesktop from "../images/pythonCode_desktop.png"
import pythonCodeMobile from "../images/pythonCode_mobile.png"

const ProjectsSection = () => {
  const projects = [
    {
      idNames: ["quickmaths-desktop", "quickmaths-mobile"],
      classNames: ["fill-quickmaths-desktop", "fill-quickmaths-mobile"],
      name: "QuickMaths",
      technologies: [
        "HTML",
        "CSS/SASS",
        "JS",
        "React",
        "Redux",
        "Ruby",
        "Rails",
        "Postgresql",
      ],
      description:
        "QuickMaths is a cutting-edge booking application designed to help students of all ages and skill levels find a qualified math tutor quickly and easily.",
      githubLink: "https://github.com/laiifuu/quickmaths-frontend",
      liveDemoLink: "https://quickmaths145.netlify.app/",
      desktopImg: quickmathsDesktop,
      mobileImg: quickmathsMobile,
    },
    {
      idNames: ["nightowl-desktop", "nightowl-mobile"],
      classNames: ["fill-nightowl-desktop", "fill-nightowl-mobile"],
      name: "The Night Owl International Book Fair",
      technologies: ["HTML", "CSS", "JS"],
      description:
        "This project is a landing page for an international book fair which invites book enthusiasts from across the globe to embark on an unforgettable adventure into the captivating realms of literature, culture, and imagination.",
      githubLink:
        "https://github.com/laiifuu/The-Night-Owl-International-Book-Fair",
      liveDemoLink:
        "https://laiifuu.github.io/The-Night-Owl-International-Book-Fair/index.html",
      desktopImg: nightOwlDesktop,
      mobileImg: nightOwlMobile,
    },
    {
      idNames: ["countries-desktop", "countries-mobile"],
      classNames: ["fill-countries-desktop", "fill-countries-mobile"],
      name: "Countries",
      technologies: ["HTML", "CSS", "JS", "React", "Redux", "Apis"],
      description:
        "Countries is a web application that revolves around the Rest Countries API. It allows users to explore information about countries from all over the globe. Think of it like an online catalogue of countries.",
      githubLink: "https://github.com/laiifuu/countries",
      liveDemoLink: "https://explore-countries-789.netlify.app/",
      desktopImg: countriesDesktop,
      mobileImg: countriesMobile,
    },
    {
      idNames: ["global-travel-desktop", "global-travel-mobile"],
      classNames: ["fill-global-travel-desktop", "fill-global-travel-mobile"],
      name: "Global Travels Services",
      technologies: ["NextJS", 'TailwindCSS', "Firebase"],
      description: "This website was a freelance project which I built for a travel agency so they could offer their services for their clients. The project also includes a dashboard to manipulate the main website's content.",
      liveDemoLink: "https://www.webglobaltravel.com/",
      desktopImg: globalTravelDesktop,
      mobileImg: globalTravelMobile,
    },
    {
      idNames: ["python-code-desktop", "python-code-mobile"],
      classNames: ["fill-python-code-desktop", "fill-python-code-mobile"],
      name: "The Python Code Homepage",
      technologies: ["HTML", 'Bootstrap'],
      description: "A freelance project where I redesigned the homepage of The Python Code blog, and implemented it using HTML and Bootstrap.",
      liveDemoLink: "https://laiifuu.github.io/thepythoncode/",
      desktopImg: pythonCodeDesktop,
      mobileImg: pythonCodeMobile,
    }
  ];
  return (
    <section className={styles["projects-section"]} id="projects">
      <h2>Projects</h2>
      <Carousel
        navButtonsAlwaysVisible="true"
        animation="slide"
        duration={1000}
        className={styles.carousel}
      >
        {projects.map((project, i) => (
          <Project key={i} project={project} />
        ))}
      </Carousel>
    </section>
  );
};

export default ProjectsSection;
