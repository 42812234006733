import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import styles from '../styles/Footer.module.css';

const Footer = () => (
  <footer>
    <ul className={styles.socials}>
      <li>
        <a className={styles.social} href="https://www.linkedin.com/in/hayetfe/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </li>
      <li>
        <a className={styles.social} href="https://github.com/laiifuu" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </li>

      <li>
        <a className={styles.social} href="mailto:hayet.fellah145@gmail.com">
          <FontAwesomeIcon icon={solid('envelope')} />
        </a>
      </li>
    </ul>
  </footer>
);

export default Footer;
