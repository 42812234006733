/* eslint-disable */

import styles from "../styles/HeroSection.module.css";
import HeroIllustration from "./HeroIllustration";

const HeroSection = () => {
  return (
    <section className={styles["hero-section"]}>
      <div className={styles["hero-section-text"]}>
        <p className={styles["hi-text"]}>Hi there, my name is</p>
        <h1 className={styles["name-job-text"]}>
          Hayet Fellah, <br /> and i'm a{" "}
          <span className={styles["colored-text"]}>Web Developer</span>
        </h1>
        <p className={styles["intro-text"]}>
          I am a Computer Scientist and Full-Stack Web Developer from Algeria,
          with a passion for crafting exceptional user experiences in web
          applications.
        </p>
        <a href="#contact"><button className={styles["btn"]}>Contact me</button></a>
      </div>
      <HeroIllustration />
    </section>
  );
};

export default HeroSection;
